import DOMPurify from "dompurify";
import React from "react";
import { FILE_TYPES_EXTENSIONS } from "../constants";

const getMIMEForExtension = (extension) => {
  switch (extension) {
    case "pdf":
      return "application/pdf";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "zip":
      return "application/zip";
    case "xlsm":
      return "application/vnd.ms-excel.sheet.macroEnabled.12";
    default:
      return "";
  }
};
const endsWithOneOf = (name, endings) => endings.some((end) => name.endsWith(end));

export const downloadFile = ({ data, filename, filetype }) => {
  const options = { type: getMIMEForExtension(filetype) };
  const url = window.URL.createObjectURL(new Blob([data], options));
  const name = endsWithOneOf(filename, FILE_TYPES_EXTENSIONS) ? filename : `${filename}.${filetype}`;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const debounce = (func, wait = 1000) => {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, wait);
  };
};
export const getFilteredDataAttributes = (props) => {
  if (props === null || typeof props !== "object") {
    return {};
  }
  const results = {};
  Object.keys(props)
    .filter((key) => key.startsWith("data-"))
    .forEach((key) => {
      results[key] = props[key];
    });
  return results;
};
export const dangerousSetInnerHTML = ({ autoTranslate = "yes", className = "", text }) => (
  /* eslint-disable */
  <span
    className={className}
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
    translate={autoTranslate}
  />
  /* eslint-enable */
);
export const isObject = (object) => object != null && typeof object === "object";

export const isDeepEqual = (object1, object2) => {
  if (!isObject(object1) || !isObject(object2)) {
    return false;
  }
  return JSON.stringify(object1) === JSON.stringify(object2);
};

export const isValueActive = (value, key, index) => {
  if (key === "categories") {
    return value?.CATEGORY?.length > 0 || value?.FAVORITE?.length > 0;
  }
  if (key === "documents") {
    return value?.elements?.length > 0 || value?.length > 0;
  }
  if (key === "tocFilters" || key === "keywordFilters") {
    return value?.filters?.[index]?.keywords?.length > 0;
  }
  if (key === "requirement") {
    return value?.elements?.length > 0 || value?.length > 0;
  }
  return ![null, undefined, ""].includes(value) && !["[]", "{}", ""].includes(JSON.stringify(value));
};

export const isHeader = (styleToTest) => {
  const headingStyles = ["heading", "titre", "header", "hocheading"];
  return headingStyles.some((hs) => styleToTest?.toLowerCase().includes(hs));
};
