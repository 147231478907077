import axios from "axios";
import { v5 as uuidv5 } from "uuid";
import { ContextProvider, translate } from "../../common/providers";
import configAxios from "./config";
import { createNotification } from "../../common/components";
import { KeycloakService } from "../../sevices";

const CONTENT_TYPE = "application/json;charset=UTF-8";
const REQ_CANCEL_MESSAGE = "Pending requests were cancelled";
const CONTENT_DISPOSITION_REGEX = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/;

const buildUrl = (url) => {
  const domain = ContextProvider.getDomain();
  if (domain === "localhost") {
    return `http://localhost:8080/v1.0${url}`;
  }
  if (domain === "gcc.group.aitenders.com") {
    return `https://gcc.apigroup2.aitenders.com/v1.0${url}`;
  }
  if (domain === "setec.group.aitenders.com") {
    return `https://setec.apigroup2.aitenders.com/v1.0${url}`;
  }
  if (domain === "vinciconstructionfrance.group.aitenders.com") {
    return `https://vinciconstructionfrance.apigroup2.aitenders.com/v1.0${url}`;
  }
  if (domain === "sandbox.group.aitenders.com") {
    return `https://sandbox.apigroup2.aitenders.com/v1.0${url}`;
  }
  if (domain === "vialpina.group.aitenders.com") {
    return `https://vialpina.apigroup3.aitenders.com/v1.0${url}`;
  }
  if (domain === "l15.projects.aitenders.com") {
    return `https://api.l15.instances.aitenders.com/v1.0${url}`;
  }
  return `https://api.${domain}/v1.0${url}`;
};
const formatError = (err) => {
  if (err?.response && typeof err.response.data === "string") {
    return err.response.data;
  }
  if (err?.response && err.response.data) {
    let { message } = err.response.data;
    if (err.response.data.message === "No message available") {
      message = err.response.data.error || translate("common:error.operation-has-failed");
    }
    return `${err.response.data.status} - ${message}`;
  }
  return err.message || err.status;
};
const translateError = (err) => {
  const errorData = err.response?.data;
  if (errorData && errorData.translationKey) {
    const params = { ...errorData.params };
    return translate(`backend:${errorData.translationKey}`, params);
  }
  return "";
};

const generateGUID = (config) => {
  let guidString = "";
  if (config?.url) {
    guidString += config.url;
  }
  if (config?.method) {
    guidString += config.method;
  }
  if (config?.data) {
    guidString += JSON.stringify(config.data);
  }
  if (config?.headers?.Authorization) {
    guidString += config.headers.Authorization;
  }
  return uuidv5(guidString, "ab671a64-40d5-491e-99b0-da01ff1f3341");
};
class Client {
  constructor() {
    const client = axios.create(configAxios);

    client.interceptors.request.use((config) => {
      const cb = () => {
        config.headers.Authorization = KeycloakService.getToken();
        return Promise.resolve(config);
      };
      if (!config.cancelToken) {
        throw new Error("No cancel token was found for this request");
      }
      if (KeycloakService.isLoggedIn() && !config.url.includes("/public")) {
        if (config.method !== "get") {
          config.headers.XGUID = generateGUID(config);
        }
      }
      config.withCredentials = true; // Sends cookies with requests
      if (KeycloakService.isLoggedIn() && !config.url.includes("/public")) {
        return KeycloakService.updateToken(cb);
      }
      return config;
    });

    client.interceptors.response.use(
      (res) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        const tkURL = res.config.url.substring(res.config.baseURL.length) || "";
        if (tkURL && ["/login"].some((uri) => tkURL.includes(uri))) {
          const token = res.headers.authorization || null;
          const data = res.data || null;
          return { data, token };
        }
        if (res?.headers && (res.headers["content-disposition"] || "").includes("filename")) {
          const contentDisposition = CONTENT_DISPOSITION_REGEX.exec(res.headers["content-disposition"]);
          let filename = contentDisposition[1];
          // eslint-disable-next-line quotes
          if ((filename || "").startsWith('"')) {
            // Strip double quotes
            filename = filename.substring(1, filename.length - 1);
          }
          return { data: res.data, filename };
        }
        return res.data;
      },
      (err) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        const formatedError = formatError(err);
        let URL = "";
        if (err.config) {
          URL = err.config.url.substring(err.config.baseURL.length) || "";
        }
        if (!ContextProvider.isLogged() && URL && ["/public/refresh"].some((uri) => URL.includes(uri))) {
          return Promise.reject(formatedError);
        }
        // Performs logout on 401s, except for the response of /refresh
        if (err.response && err.response.status === 401) {
          return Promise.reject(formatedError);
        }
        const translatedError = translateError(err);
        createNotification({ type: "error", message: translatedError || formatedError });
        return Promise.reject(formatedError);
      }
    );

    this.client = client;
  }

  get(url, { params, config = {} } = {}) {
    return this.client.get(buildUrl(url), { params, ...config });
  }

  delete(url, { params, config = {} } = {}) {
    return this.client.delete(buildUrl(url), { params, ...config });
  }

  post(url, { payload = null, params, config = {} } = {}) {
    Object.assign(config, {
      headers: { ...config?.headers, "Content-Type": CONTENT_TYPE },
    });
    return this.client.post(buildUrl(url), payload, { params, ...config });
  }

  put(url, { payload = null, params, config = {} } = {}) {
    Object.assign(config, { headers: { ...config?.headers, "Content-Type": CONTENT_TYPE } });
    return this.client.put(buildUrl(url), payload, { params, ...config });
  }

  patch(url, { payload = null, params, config = {} } = {}) {
    Object.assign(config, { headers: { ...config?.headers, "Content-Type": CONTENT_TYPE } });
    return this.client.patch(buildUrl(url), payload, { params, ...config });
  }
  download(url, { params, config = {} } = {}) {
    return this.client.get(buildUrl(url), {
      params,
      ...config,
      responseType: "arraybuffer",
    });
  }

  getCancelTokenSource() {
    return axios.CancelToken.source();
  }

  cancelTokens(cancelTokenSource) {
    cancelTokenSource.cancel(REQ_CANCEL_MESSAGE);
  }
  isRequestCancellation(errorMessage) {
    return errorMessage === REQ_CANCEL_MESSAGE;
  }
}

export default new Client();
