import { MASTER_PROMPT_UPDATED, MASTER_PROMPT_VALUE } from "../../../common/providers/keys/keys";

class MultiProjectSearchContext {
  constructor() {
    this.storage = window.sessionStorage;
  }
  setMasterPrompValue(masterPrompt) {
    this.storage.setItem(MASTER_PROMPT_VALUE, masterPrompt);
  }
  getMasterPrompValue() {
    return this.storage.getItem(MASTER_PROMPT_VALUE);
  }
  setMasterPrompUpdated(boolean) {
    this.storage.setItem(MASTER_PROMPT_UPDATED, boolean);
  }
  getMasterPrompUpdated() {
    return this.storage.getItem(MASTER_PROMPT_UPDATED);
  }
}
export default new MultiProjectSearchContext();
