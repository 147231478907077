import { createSlice } from "@reduxjs/toolkit";
import { getPage } from "./multi-project-search-thunk";
import { SEARCH_TABS, TABS } from "../constants/tabs";
import { OPERATORS } from "../../../common/constants";
import { removeNullFilters } from "./multi-project-search-utils";
import multiProjectSearchContext from "../context/multi-project-search-context";

const initialState = {
  content: [],
  selectedTab: TABS.SEARCH,
  selectedSearchTab: SEARCH_TABS.PROJECT,
  selectedQuickActions: [],
  loadingAnswer: false,
  genOpenSavedQA: true,
  genOpenSavedDeliv: false,
  genOpenSavedProject: false,
  currentEditContentIndex: 1,
  tourEnabled: false,
  filters: { separator: OPERATORS.AND, input: "", granularity: [] },
  projectFilters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
  masterPrompt: {
    updated: multiProjectSearchContext.getMasterPrompUpdated() || false,
    value:
      multiProjectSearchContext.getMasterPrompValue() ||
      `Je vais te donner des consignes que tu vas devoir suivre à la lettre. Il est possible que tu reçoives dans "Autres consignes" des consignes contradictoires auxquelles tu essaieras au maximum de te conformer, peu importe la demande initiale.


# Rôle
Tu es un rédacteur de document pour une entreprise et tu rédiges en son nom. 
Tu es amené à rédiger des documents qui seront scrupuleusement analysés par des personnes qui seront amener à évaluer la conformité de ta réponse. Ton style de rédaction doit permettre une certaine aisance dans la lecture ainsi qu'une logique dans la succession des sujets.

# Rédaction
Ta rédaction doit avoir pour but de convaincre l'interlocuteur de la maîtrise du sujet et de la compétence de ton entreprise
Il faut éviter les longues énumérations ou les phrases trop longues. Utilise des synonymes pour éviter les répétitions. Evite de parler au passé, préfère le présent ou le futur. Evite de faire des paragraphes trop courts ou trop longs. Ne parle pas de prix.
Pour t'aider dans ta rédaction, des contenus te sont fournis. Si des éléments te sont fournis, il faut t'en tenir strictement à ce qui t'a été fourni et éviter les phrases trop génériques ou à tendance commerciale. 

La "documentation du projet" te permet de comprendre quel est le sujet sur lequel il faut que tu rédiges. Il faut absolument que ce que tu rédiges soit conforme à ce qui est demandé dans la "documentation du projet"
Tu pourras trouver des idées fortes à intégrer à ta rédaction dans les "autres consignes". De la "documentation de référence" est à ta disposition pour trouver de l'inspiration sur des éléments à intégrer à la rédaction.
Il est possible que tu aies à rédiger en l'absence de documentation du projet ou de documentation de référence. Il faudra alors trouver des choses à rédiger sans inventer d'éléments qui ne seraient pas présents dans le texte.
Il faudra autant que possible respecter la langue utilisée dans la "documentation du projet"


# Autres consignes
PROMPT_INDICATION

# Documentation du projet
PROJECT_CONTENT


# Documentation de référence
LIVRABLE_CONTENT  `,
  },
};
const multiProjectSearchSlice = createSlice({
  name: "multiProjectSearch",
  initialState,
  reducers: {
    resetContent: (state) => ({
      ...state,
      content: [],
    }),
    setLoadingAnswer: (state, { payload }) => ({ ...state, loadingAnswer: payload }),
    setSelectedTab: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
    }),
    incrementEditContentIndex: (state) => ({
      ...state,
      currentEditContentIndex: state.currentEditContentIndex + 1,
    }),
    setEditContentIndex: (state, { payload }) => ({
      ...state,
      currentEditContentIndex: payload,
    }),
    setGenOpenSavedProject: (state, { payload }) => ({
      ...state,
      genOpenSavedProject: payload,
    }),
    setGenOpenSavedDeliv: (state, { payload }) => ({
      ...state,
      genOpenSavedDeliv: payload,
    }),
    setGenOpenSavedQA: (state, { payload }) => ({
      ...state,
      genOpenSavedQA: payload,
    }),
    setTourEnabled: (state, { payload }) => ({
      ...state,
      tourEnabled: payload,
    }),
    decrementEditContentIndex: (state) => ({
      ...state,
      currentEditContentIndex: state.currentEditContentIndex - 1,
    }),
    setSelectedSearchTab: (state, { payload }) => ({
      ...state,
      selectedSearchTab: payload,
    }),
    updateMasterPrompt: (state, { payload }) => {
      multiProjectSearchContext.setMasterPrompUpdated(true);
      multiProjectSearchContext.setMasterPrompValue(payload);
      return {
        ...state,
        masterPrompt: { value: payload, updated: true },
      };
    },
    setFilters: (state, { payload }) => {
      const newFilters = removeNullFilters(payload);
      return {
        ...state,
        filters: newFilters,
      };
    },
    setProjectFilters: (state, { payload: filters }) => {
      const newFilters = removeNullFilters(filters);
      return {
        ...state,
        projectFilters: newFilters,
      };
    },
    setSelectedQuickAction: (state, { payload }) => ({ ...state, selectedQuickActions: payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getPage.pending, (state, action) => {
      const { page } = action.meta.arg;
      return {
        ...state,
        content: [...state.content, { page, isLoading: true }],
      };
    });
    builder.addCase(getPage.fulfilled, (state, action) => {
      const { page } = action.meta.arg;
      const { payload } = action;
      const blobImage = URL.createObjectURL(payload);
      return {
        ...state,
        content: state.content.map((c) =>
          c.page === page ? { ...c, page, image: blobImage, isLoading: false } : { ...c, truePage: c.page }
        ),
      };
    });
    builder.addCase(getPage.rejected, (state, action) => {
      const { page } = action.meta.arg;
      return {
        ...state,
        content: state.content.map((c) => (c.page === page ? { ...c, page, image: null, isLoading: false } : c)),
      };
    });
  },
});
export { getPage };
export const {
  resetContent,
  setSelectedTab,
  setSelectedSearchTab,
  setFilters,
  setTourEnabled,
  setProjectFilters,
  setGenOpenSavedDeliv,
  setGenOpenSavedProject,
  setGenOpenSavedQA,
  incrementEditContentIndex,
  setEditContentIndex,
  decrementEditContentIndex,
  setLoadingAnswer,
  setSelectedQuickAction,
  updateMasterPrompt,
} = multiProjectSearchSlice.actions;
export default multiProjectSearchSlice.reducer;
