import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomIconButton,
  GenericDialog,
  GenericDialogContent,
  GenericDialogHeader,
  icon,
  IconComponent,
} from "../../../../../../../common/components";
import styles from "./MasterDialog.module.css";
import CustomTextField from "../../../../../../../common/components/text-fields/text-field/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { updateMasterPrompt } from "../../../../../slice/multi-project-search-slice";
import { translate } from "../../../../../../../common/providers";

const MasterDialog = ({ onClose, open }) => {
  const masterPromptValue = useSelector(({ multiProjectSearch }) => multiProjectSearch.masterPrompt?.value);
  const [customPrompt, setCustomPrompt] = useState(masterPromptValue || "");
  const dispatch = useDispatch();
  useEffect(() => {
    setCustomPrompt(masterPromptValue);
  }, [masterPromptValue]);
  const handleChange = (e) => {
    setCustomPrompt(e.target.value);
  };
  const handleUpdateMasterPrompt = () => {
    dispatch(updateMasterPrompt(customPrompt));
    onClose();
  };
  return (
    <GenericDialog className={styles.dialog} open={open} onClose={onClose}>
      <GenericDialogHeader>
        <div className={styles.header}>
          <span>{translate("multi-projects-search.generate.master-prompt.title")}</span>{" "}
          <CustomIconButton icon={icon.faTimes} onClick={onClose} />
        </div>
      </GenericDialogHeader>
      <GenericDialogContent className={styles.dialogContent}>
        <div className={styles.contentContainer}>
          <div className={styles.helperContainer}>
            <IconComponent color="var(--color-blue)" icon={icon.faInfoCircle} size="4xl" />
            <div>
              <div>{translate("multi-projects-search.generate.master-prompt.helper-text")}</div>
              <div>PROMPT_INDICATION</div>
              <div>PROJECT_CONTENT</div>
              <div>LIVRABLE_CONTENT</div>
            </div>
          </div>
          <CustomTextField
            fullWidth
            multiline
            className={styles.textfield}
            rows={3}
            value={customPrompt}
            onChange={handleChange}
          />
          <CustomButton variant="contained" onClick={handleUpdateMasterPrompt}>
            {translate("multi-projects-search.generate.master-prompt.update-button")}
          </CustomButton>
        </div>
      </GenericDialogContent>
    </GenericDialog>
  );
};

export default MasterDialog;
