import { MenuItem } from "@mui/material";
import React, { useRef } from "react";
import styles from "./MenuElement.module.css";
import SubMenus from "./SubMenus";
import { VerticalDivider, IconComponent, icon } from "..";
import { isNonEmptyArray } from "../../utils";
const MenuElement = ({ menu, onCloseSubMenu, openedSubMenu }) => {
  const anchorRef = useRef();
  if (menu.isHeader) {
    return <div className={styles.header}>{menu.title}</div>;
  }
  return (
    <MenuItem
      key={menu.title}
      ref={anchorRef}
      className={styles.menuItem}
      disabled={menu.disabled}
      onClick={menu.action}
    >
      <span className={styles.menuText}>
        <IconComponent className={styles.menuIcon} color="var(--color-light-grey-1)" icon={menu.icon} size="xs" />
        {menu.title}
      </span>
      {isNonEmptyArray(menu.subMenus) && (
        <div className={styles.expandContainer}>
          <VerticalDivider inFlexContainer />
          <IconComponent color="var(--color-light-grey-1)" icon={icon.faChevronRight} size="xs" />
          <SubMenus
            ref={anchorRef}
            open={openedSubMenu === menu.value}
            subMenus={menu.subMenus}
            onClose={onCloseSubMenu}
          />
        </div>
      )}
    </MenuItem>
  );
};

export default MenuElement;
