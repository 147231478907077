import React, { useEffect, useState } from "react";
import { translate } from "../../../../../../../common/providers";
import { CustomButton } from "../../../../../../../common/components";
import styles from "./GenerationActions.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setEditContentIndex, setLoadingAnswer, setSelectedTab } from "../../../../../slice/multi-project-search-slice";
import { useApi } from "../../../../../../../common/hooks";
import { ContentService } from "../../../../../../../api";
import { TABS } from "../../../../../constants/tabs";

const GenerationActions = ({ savedDeliverableContentCount, savedProjectContentCount, userPromptCount }) => {
  const { call: getGenerate } = useApi(ContentService.getGenerate);
  const { call: getAskedQuestionCount } = useApi(ContentService.getAskedQuestionCount);
  const { call: testCustomPrompt } = useApi(ContentService.testCustomPrompt);
  const projectId = useSelector(({ context }) => context.project?.id);
  const masterPrompt = useSelector(({ multiProjectSearch }) => multiProjectSearch.masterPrompt);
  const [chatsCount, setChatsCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getAskedQuestionCount()
      .then((data) => setChatsCount(data))
      .catch(console.error);
  }, [getAskedQuestionCount]);
  const handleGeneration = () => {
    if (savedDeliverableContentCount <= 0 && userPromptCount <= 0 && savedProjectContentCount <= 0) {
      return;
    }
    dispatch(setLoadingAnswer(true));
    if (masterPrompt.updated) {
      testCustomPrompt(
        projectId
          ? {
              projectId,
              question: masterPrompt.value,
            }
          : { projectId: undefined, question: masterPrompt.value }
      )
        .then(() => {
          dispatch(setEditContentIndex(chatsCount + 1));
        })
        .catch(console.error)
        .finally(() => {
          dispatch(setLoadingAnswer(false));
          dispatch(setSelectedTab(TABS.EDITION));
        });
    } else {
      getGenerate(
        projectId
          ? {
              projectId,
            }
          : { projectId: undefined }
      )
        .then(() => {
          dispatch(setEditContentIndex(chatsCount + 1));
        })
        .catch(console.error)
        .finally(() => {
          dispatch(setLoadingAnswer(false));
          dispatch(setSelectedTab(TABS.EDITION));
        });
    }
  };
  return (
    <>
      <div className={styles.generationActions}>
        <CustomButton
          disabled={savedDeliverableContentCount <= 0 && userPromptCount <= 0 && savedProjectContentCount <= 0}
          variant="contained"
          onClick={handleGeneration}
        >
          {translate("multi-projects-search.generate.btn.generate-chapter")}
        </CustomButton>
      </div>
      <div className={styles.helperInfo}>{translate("multi-projects-search.ai-helper-text")}</div>
    </>
  );
};

export default GenerationActions;
