export const KEY_LANG = "lang";
export const KEY_DELIVERABLE_NAME = "deliverable_name";
export const KEY_DELIVERABLE_ID = "deliverable_id";
export const KEY_DELIVERABLE_OWNER_ID = "deliverable_owner_id";
export const KEY_DELIVERABLE_OWNER_NAME = "deliverable_owner_name";
export const KEY_PROJECT_NAME = "project_name";
export const KEY_PROJECT_ID = "project_id";
export const KEY_PROJECT_REQ_TO_DELIV_NAME = "projectreq_to_deliv_name";
export const KEY_PROJECT_REQ_TO_DELIV_ID = "project_req_to_deliv_id";
export const KEY_DOMAIN = "domain";
export const MASTER_PROMPT_VALUE = "master_prompt_value";
export const MASTER_PROMPT_UPDATED = "master_prompt_updated";
